import {Container, Box} from "@mui/material"

const PreviewLink = ({title, description, tags, filter, created, imgSrc}) => {
    const tagPresent = tags.includes(filter)
    const urlDest = `/blog/${title.replaceAll(" ", "_")}`
    const displayItem = filter === "all" ? true: tagPresent
    const clickNav = () => {
        window.location = urlDest
    }
    return (
        <Container disableGutters>
            {displayItem && (
                <Container disableGutters className="previewLink">
                    <Container onClick={clickNav} sx={{display: 'flex', justifyContent: 'space-between'}} fullWidth disableGutters>
                    <a style={{marginRight: "10px"}} href={urlDest}>{ title }</a>
                    <span>{created.toDateString()}</span>
                </Container>
                    <Box sx={{display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexMode: 'column'}}>
                        <div>{description}</div>
                        {/*<div>{tags.map((t) => <div>{t}</div>)}</div>*/}
                        <img style={{height: '80px', width: '80px'}} src={imgSrc}/>
                    </Box>
                </Container>
            )}
        </Container>
    );
}

export default PreviewLink;