import ToolBadge from "./components/ToolBadge";
import {Typography} from "@mui/material";

const Tools = () => {
    return (
        <div>
            <Typography textAlign='center' variant='h3'>Tools</Typography>
            <Typography textAlign='center'>
                This is a collection of software I made for fun or because I wanted to have it available
                for my own use. If you have ideas to build, reach out!
            </Typography>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <ToolBadge
                    text={'AI Developer'}
                    description={'This is a tool for writing code for you using AI using text prompts'}
                    path={"/tools/pythondev"}
                />

                <ToolBadge
                    text={'Image Generator'}
                    description={'Create custom images from text descriptions'}
                    path={"/tools/imagegen"}
                />
                <ToolBadge
                    text={"Meal Planner"}
                    description={"This is a tool for planning meals for the week"}
                    path={"/tools/mealplanner"}
                    disabled
                />

                {/*<ToolBadge*/}
                {/*    text={'Podcast Summarizer'}*/}
                {/*    description={'This is a tool for summarizing a podcast with AI'}*/}
                {/*    disabled*/}
                {/*/>*/}
                {/*<ToolBadge*/}
                {/*    text={'Budget Tool'}*/}
                {/*    description={'This is a tool for building a budget'}*/}
                {/*    disabled*/}
                {/*/>*/}
                {/*<ToolBadge*/}
                {/*    text={'Webpage Summary'}*/}
                {/*    description={'This is a tool for reading and summarizing a webpage for you'}*/}
                {/*    disabled*/}
                {/*/>*/}
                {/*<ToolBadge*/}
                {/*    text={'Open Source Index'}*/}
                {/*    description={'This is a tool navigating open source code projects'}*/}
                {/*    disabled*/}
                {/*/>*/}
            </div>
        </div>
    )
}

export default Tools;