import AudioRecorder from "./AudioRecorder";

const AskJak = () => {
    return (
        <div>
            <AudioRecorder />
        </div>
    )
}

export default AskJak;
