import {Container, Typography, Button} from "@mui/material";

const MealPlanner = () => {
    return (
        <Container>
            <Typography variant='h3' textAlign='center'>Meal Planner</Typography>
            <Button variant="contained" sx={{backgroundColor: 'var(--font-color)', color: 'var(--font-color)'}}>+ Add Recipe</Button>
        </Container>
    );
}

export default MealPlanner;
