import {Container, Box, Typography} from "@mui/material"

const CheatSheets = () => {
    return (
        <Container>
            <Typography variant="h1">
                Cheat Sheets
            </Typography>
            <Box>
                <a href="/cheatsheets/git">Git Cheat Sheet</a>
            </Box>
        </Container>
    );
}

export default CheatSheets;