import Container from "@mui/material/Container"
import {Typography} from "@mui/material";
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

const ProjectBadge = ({title, description, targetUrl}) => {
    return (
        <Card sx={{display: "flex",
            flexDirection: "column",
            border: "solid 1px",
            width: "500px",
            padding: "12px",
            borderRadius: "12px",
            margin: "6px",
            backgroundColor: "var(--color-foreground)",
            color: "var(--color-font-nav)"
        }}>
            <CardActionArea href={targetUrl}>
                <Typography variant='h3' sx={{fontSize: "2rem", margin: "4px",}}>{title}</Typography>
                <Typography sx={{fontSize: "medium"}}>{description}</Typography>
            </CardActionArea>
        </Card>
    )
}

export default ProjectBadge;