import {Select, MenuItem, Dialog, DialogTitle, Button, Container, Typography, InputLabel, TextField, FormControl} from "@mui/material";
import PreviewLink from "./PreviewLink";
import {useEffect, useState} from "react";
import SubscribeDialog from "./components/subscribeDialog"
import './App.css';


import ReCAPTCHA from 'react-google-recaptcha';
export const Home = () => {
    const [showContact, setshowContact] = useState(false)
    const [tagFilter, setTagFilter] = useState('all')
    const [blogs, setBlogs] = useState();

    const getBlogs = () => {
        console.log("getting blogs")
        fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs`).then((resp) => {
            if (resp.ok){
                return resp.json()
            }
            throw new Error("not ok resp")
        }).then((data) => {
            const items = JSON.parse(data.items);
            setBlogs(items)

        })
    }

    useEffect(() => getBlogs(), []);

    return (
        <Container maxWidth>
            <Typography variant='h3' textAlign='center'>Posts</Typography>
            <SubscribeDialog />
            <Container>
                <Container sx={{padding: 0}}>
                    <FormControl>
                        <InputLabel id='tag-input' sx={{color: 'var(--font-color)'}}>Tag</InputLabel>
                        <Select
                            label='Tag'
                            id='tag-input-select'
                            labelId='tag-input'
                            value={tagFilter}
                            sx={{
                                color: 'var(--font-color)',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--font-color)'
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'var(--font-color)'
                                }
                            }}
                            onChange={(e) => setTagFilter(e.target.value)}
                        >
                            <MenuItem value={"all"}>all</MenuItem>
                            <MenuItem value={"about"}>about</MenuItem>
                            <MenuItem value={"Programming"}>programming</MenuItem>
                        </Select>
                    </FormControl>
                </Container>
                <Container sx={{padding: 0}}>
                { blogs &&
                    blogs.map((blog) => (<PreviewLink
                        title={blog.fields.title}
                        description={blog.fields.description}
                        tags={blog.fields.tags.split(',')}
                        created={new Date(blog.fields.created)}
                        filter={tagFilter}
                        imgSrc={blog.fields.imgpath}
                    />))
                }
                </Container>
            </Container>
        </Container>
    );
};

export default Home;