import ReactMarkdown from "react-markdown";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useParams } from 'react-router';
import {useEffect, useState} from "react";
import SubscribeDialog from "./subscribeDialog";

const BlogArticle = () => {
    const { title } = useParams();
    const [data, setData] = useState();

    const getBlog = () => {
        const titleSpaces = title.replaceAll("_", " ")
        fetch(`${process.env.REACT_APP_API_BASE_URL}/blog/${titleSpaces}`).then((resp) => {
            if (resp.ok){
                return resp.json()
            }
            throw new Error("Error in api")
        }).then((data) => {
            setData(data.data);
        })
    }

    useEffect(() => getBlog(), [])

    return (
        <Container
            className="blogArticle"
            sx={{padding: '10px'}}>
            <ReactMarkdown>
                {data}
            </ReactMarkdown>
            <SubscribeDialog />
            <Container sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}>
                <Button variant='contained' href="/">Return Home</Button>
            </Container>
            <Container sx={{height: "200px"}}>

            </Container>
        </Container>
    )
}

export default BlogArticle;