import {Box, Container, Typography, Button} from "@mui/material";

const Thankyou = () => {
    return <Container>
        <Typography textAlign='center'
                    sx={{margin: "20px"}}
                    variant='h4'>
            Thank you for subscribing!

            I hope you get value out of the subscription.
            <Box>
                <Button variant='contained' href="/">Return Home</Button>
            </Box>
        </Typography>
    </Container>
}

export default Thankyou
