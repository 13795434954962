import {Container, Button, Typography, Divider} from '@mui/material';
import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";

const LandingPage = () => {
    const [blogs, setBlogs] = useState();

    const getBlogs = () => {
        console.log("getting blogs")
        fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs/get_blogs_with_pages`).then((resp) => {
            if (resp.ok){
                return resp.json()
            }
            throw new Error("not ok resp")
        }).then((data) => {
            const items = data.data;
            setBlogs(items)
        })
    }

    useEffect(() => getBlogs(), []);

    return (
        <Container>
            <Button variant="contained" href="/home">All Posts</Button>
            {blogs &&
                 blogs.map((data) => {
                     return <Container>
                             <ReactMarkdown>
                                {data.markdown}
                            </ReactMarkdown>
                            <Typography>Posted {new Date(data.created).toDateString()}</Typography>
                            <Divider sx={{border: "solid 4px black"}} />
                         </Container>
                 })
            }
        </Container>
    )
}

export default LandingPage;
