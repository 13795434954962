import {Typography} from "@mui/material";

const ToolBadge = ({ text, color, description, path, disabled = false }) => {
  let bgColor = color || "var(--color-badge)"
  if (disabled){
      bgColor = 'gray'
  }

  const styleDict = {
      backgroundColor: bgColor,
      height: '125px'
  }

  if (disabled){
      styleDict["cursor"] = "not-allowed"
  }

  return (
    <div className="toolBadge" style={styleDict}>
        <div>
            {!disabled && <a style={{color: 'white', textDecoration: 'none'}} href={path}>{text}</a>}
            {disabled && <span>{text}</span>}
        </div>
        {disabled && <div>
                <Typography color='red'>Coming Soon</Typography>
            </div>
        }
        <div style={{
            display: 'flex',
            textAlign: 'center',
            fontSize: 'small',
            margin: '10px',
          }}
        >
            <div>
                {description}
            </div>
        </div>
    </div>
  );
};

export default ToolBadge;
