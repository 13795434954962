import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import {Button} from "@mui/material";

const Resume = () => {
    return (
        <Container>
            <Typography variant="h2" textAlign='center' sx={{"fontSize": "large"}}>Jared Welch's Resume</Typography>
            <Typography sx={{"padding": "10px"}}>
                Greetings! I'm Jared Welch. As a Senior Software Developer, I have had the privilege
                to work for almost a decade in the software services industry. I love coding, professionally and as a hobby.
            </Typography>
            <Typography sx={{"padding": "10px"}}>
                I love the cloud (GCP > AWS > Azure for my preferences) and especially love automation of infrastructure
                using a combination of build pipelines and infrastructure as code, including automated test suites.
            </Typography>
            <Typography sx={{"padding": "10px"}}>
                This website uses gitlab pipelines to build containers for the services hosted in Amazon Fargate that power the backend
                and AWS hosting for the static frontend written in React.js. I run tests with pytest. Docker for the
                container management. Backend uses Python and Django + sqlite3 (maybe postgres someday)
            </Typography>
            <Typography sx={{"padding": "10px"}}>
                If any of that sounds cool to you, you can send me an email at <a href="mailto:jared@jaredbwelch.com">jared@jaredbwelch.com</a>

                Thank you for stopping by, and I look forward to connecting with you!

                If you are here for my resume, you can see it here:
                <Container sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <Button variant="contained" href="/JaredWelchResume.pdf">Jared's Resume</Button>
                </Container>


            </Typography>
        </Container>
    )
}

export default Resume;
