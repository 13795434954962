import React, {useState, useRef, useEffect} from 'react';
import { Button, Container } from '@mui/material';

const AudioRecorder = () => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [recording, setRecording] = useState(false);
  const audioRef = useRef(null);
  const [jakResponse, setResponse] = useState()

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        setChunks([])

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            setChunks(prevChunks => [...prevChunks, e.data]);
          }
        };

        recorder.onstop = () => {
          // do nothing
        };

        recorder.start();
        setRecording(true);
      })
      .catch((err) => {
        console.error(`Error accessing microphone: ${err}`);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handleDeleteClip = () => {
    audioRef.current.src = null;
    setChunks([])
  };

  useEffect(() => {
    if (chunks.length > 0) {
      const blob = new Blob(chunks, { type: 'audio/mpeg' });
      const audioURL = URL.createObjectURL(blob);
      audioRef.current.src = audioURL;
    }
  }, [chunks]);

const resizeImg = async (file) => {
  return new Promise((resolve) => {
    let img = document.createElement("img");
    let reader = new FileReader();

    reader.onload = (e) => {
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        let MAX_WIDTH = 400;
        let MAX_HEIGHT = 300;
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas content to a base64 encoded string
        const dataUrl = canvas.toDataURL('image/jpeg', 0.95);
        resolve(dataUrl);
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  });
};

  const handleImage = async (e) => {const file = e.target.files[0];
      let resizedBlob = await resizeImg(file);
      resizedBlob = resizedBlob.replace(/^data:image\/(png|jpg);base64,/, "");
      fetch('https://f9mpcyegie.execute-api.us-west-2.amazonaws.com/expert-developer-prompt', {
        method: "POST",
        body: JSON.stringify({"image": resizedBlob})
      }).then((resp) => {
        if (resp.ok){
            return resp.json()
        }
        throw new Error("failed")
      }).then((data) => {
          setResponse(data.message)
      });

  }

  return (
    <Container>
      <Button onClick={startRecording} disabled={recording}>
        Start Recording
      </Button>
      <Button onClick={stopRecording} disabled={!recording}>
        Stop Recording
      </Button>

      <Container>
          {chunks.length > 0 && <audio ref={audioRef} controls />}
          {chunks.length > 0 && <Button onClick={handleDeleteClip}>Delete Clip</Button>}
      </Container>
        <input type="file" accept="image/*" onChange={handleImage}/>
        {(chunks.length > 0)}

        {jakResponse && <Container>{jakResponse}</Container>}

    </Container>
  );
};

export default AudioRecorder;
