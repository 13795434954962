import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {Typography, CircularProgress} from "@mui/material";
import {useState} from 'react';
import ReactMarkdown from "react-markdown";

import {submitPythonPrompt} from "../utils/API";

const PythonDev = () => {
    const [isWaiting, setWaiting] = useState(false);
    const [resp, setResponse] = useState();
    const [inputPrompt, setPrompt] = useState('');

    const submitPrompt = () => {
        setWaiting(true);
        setResponse(undefined);
        submitPythonPrompt(inputPrompt).then((resp) => {
            setResponse(resp?.response_prompt)
            setWaiting(false)
        }).catch((err)=>{
            setWaiting(false)
            setResponse(undefined)
            console.log(err)
            alert("failure to reach api")
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        submitPrompt()
    }

    const handleEnterKey = (e) => {
        if (e.keyCode === 13){
            submitPrompt()
        }
    }

    return (
        <Container>
            <Typography textAlign='center' variant="h3">AI Developer Prompt</Typography>
            { !isWaiting &&
            <Container>
                <form name='promptForm' onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <TextField
                        multiline
                        rows={4}
                        placeholder="Please enter your python code request"
                        sx={{fieldset: {borderColor: 'var(--color-font)'}}}
                                inputProps={{style: {color: 'var(--color-font)',
                                }}}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={handleEnterKey}
                    />
                </FormControl>
                <Box textAlign='center'>
                    <FormControl>
                        <Button sx={{color: 'var(--color-font-nav)', backgroundColor: 'var(--color-foreground)'}} type="submit" variant="contained">Submit</Button>
                    </FormControl>
                </Box>
                </form>
            </Container>}
            {isWaiting &&
                <Box textAlign='center'>
                    <CircularProgress/>
                </Box>
            }
            {resp && <ReactMarkdown>{resp}</ReactMarkdown>}
        </Container>
    );
}

export default PythonDev;
