const BASE_URL = process.env.REACT_APP_API_BASE_URL

const submitPythonPrompt = (inputPrompt) => {
    return fetch(`${BASE_URL}/pythondev?input_prompt=${inputPrompt}`,{
            method: "GET"
        }
    ).then((resp) => {
        if (resp.status !== 200){
            throw new Error("Error in api request: " + resp.errored)
        }
        return resp.json()
    }).catch((err)=> {
        throw err
    })
}

export {
    submitPythonPrompt
}