import {
    Container,
    Box,
    FormControl,
    TextField,
    Typography,
    Select,
    MenuItem,
    CircularProgress,
    InputLabel
} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from 'react';

const ImageGen = () => {
    const [prompt, setPrompt] = useState();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const handleSubmitForm = (e) => {
        e.preventDefault()
        setImageUrl(undefined)
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_BASE_URL}/image_gen?prompt=${prompt}`, {
            method: 'GET'
        }).then((resp) => {
            if (resp.ok){
                 return resp.json()
            }
            throw new Error("err in request")
        }).then((data) => {
            console.log(data)
            setImageUrl(data?.img_url)
            setLoading(false)
        }).catch((err) => {
            alert("Error reaching api")
        })
    }
    return (
        <Container>
            <Typography variant='h1' textAlign='center'>
                Image Generator
            </Typography>
            {!loading &&
            <Container>
                <form onSubmit={handleSubmitForm}>
                    <Container sx={{display: "flex", alignItems: 'center'}}>
                        <FormControl>
                            <InputLabel sx={{color: "var(--color-font)"}} label='dimension-select-label'>Dimensions</InputLabel>
                            <Select
                                value="default"
                                label="Dimensions"
                                labelId="dimension-select-label"
                                sx={{
                                    color: 'var(--color-font)',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-font)'
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: 'var(--color-font)'
                                    }
                                }}
                            >
                                <MenuItem value="default">1024x1024</MenuItem>
                            </Select>
                        </FormControl>
                    </Container>
                    <Container textAlign='center'>
                        <FormControl fullWidth>
                            <TextField
                                multiline
                                rows={4}
                                sx={{fieldset: {borderColor: 'var(--color-font)'}}}
                                inputProps={{style: {color: 'var(--color-font)',
                                }}}
                                placeholder={"Enter the description for your desired image"}
                                onChange={(e) => setPrompt(e.target.value)}
                            />
                        </FormControl>
                    </Container>
                    <Box textAlign='center'>
                        <FormControl>
                            <Button sx={{color: 'var(--color-font-nav)', backgroundColor: 'var(--color-foreground)'}} variant="contained" type="submit">Submit</Button>
                        </FormControl>
                    </Box>
                </form>
            </Container> }
            {loading &&
                <Container textAlign='center'>
                    <CircularProgress />
                </Container>
            }
            {imageUrl && <img src={imageUrl} />}
        </Container>
    )
}

export default ImageGen