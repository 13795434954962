// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './Home';
import NavBar from "./NavBar";
import Newsletter from "./Newsletter";
import Podcasts from "./Podcasts";
import Tools from "./Tools";
import BlogArticle from "./components/BlogArticle";
import AboutMe from "./AboutMe";
import PythonDev from "./components/PythonDev";
import ImageGen from "./components/ImageGen"
import CheatSheets from "./components/CheatSheets"
import GitCheatSheet from "./components/GitCheatSheet";
import {Box, IconButton, Typography, Container} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MealPlanner from "./components/MealPlanner";
import Thankyou from "./components/thankyou";
import ReactGA from 'react-ga4'
import {createBrowserHistory} from 'history'
import PremiumSubscription from "./components/premiumSubscription";
import HireMe from "./components/HireMe";
import Resume from "./components/Resume";
import AskJak from "./components/askJak";
import LandingPage from "./components/LandingPage";

const history = createBrowserHistory()
history.listen(location => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "View " + window.location.pathname });
})

function App() {
  React.useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "View " + window.location.pathname });
    
  }, [])
  return (
    <div className={"App"}>
      <GoogleOAuthProvider clientId="test">
        <NavBar/>
          <Router>
            <Container sx={{minHeight: '80vh'}}>

              {/*<Route path="/about">*/}
              {/*</Route>*/}
              {/*<Route path="/apps">*/}
              {/*</Route>*/}
            <Routes>
              <Route path="/" element={<AboutMe />} />

              <Route path="/blog" element={<LandingPage /> } />
              <Route path="/thankyou" element={<Thankyou />} />
              <Route path="/blog/:title" element={<BlogArticle />} />
              <Route path="/resume" element={<Resume />} />
              {/*<Route path="/newsletter" element={<Newsletter/> } />*/}
              {/*<Route path="/podcast" element={<Podcasts />} />*/}
              {/*<Route path="/guides" element={<Podcasts/>} />*/}
              {/*<Route path="/hireme" element={<HireMe />} />*/}
              <Route path="/tools" element={<Tools />} />
              <Route path="/tools/pythondev" element={<PythonDev />} />
              <Route path="/tools/imagegen" element={<ImageGen />} />
              <Route path="/tools/mealplanner" element={<MealPlanner />} />
              <Route path="/cheatsheets" element={<CheatSheets />} />
              <Route path="/cheatsheets/git" element={<GitCheatSheet />} />
              <Route path="/premiumSubscription" element={<PremiumSubscription /> } />
              <Route path="/home" element={<Home />} />
              <Route path="/askjak" element={<AskJak />} />

            </Routes>

        </Container>
            {/*<Box textAlign='center'>*/}
            {/*    <Typography>Footer</Typography>*/}
            {/*</Box>*/}
        </Router>
      </GoogleOAuthProvider>
    </div>

  );
}

export default App;
