import {Typography} from "@mui/material";

const GitCheatSheet = () => {
    return (
        <div>
            <Typography>
                Git Command Cheat Sheet
            </Typography>
            <div>
                git status
            </div>
            <div>
                git diff
            </div>
            <div>
                git checkout
            </div>
            <div>
                git commit -m "commit message"
            </div>
            <div>
                git reset
            </div>
            <div>
                git switch
            </div>
            <div>
                git stash
            </div>
            <div>
                git stash list
            </div>
            <div>
                git stash drop
            </div>
            <div>
                git stash pop
            </div>
            <div>
                git rebase
            </div>
            <div>
                git merge
            </div>
        </div>
    )
}

export default GitCheatSheet;