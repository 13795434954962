import {Typography, Box, Container, IconButton, Button} from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import React from "react";
import ProjectBadge from "./components/ProjectBadge";
import Divider from '@mui/material/Divider';
import {useDarkMode} from "./hooks/useDarkMode";

const AboutMe = () => {
    const {isDark} = useDarkMode()
    let divColor = isDark ? "white": "black"
    return (
        <Container style={{fontSize: 'medium'}}>
            <Typography textAlign='center' variant='h3' sx={{margin: "3px"}}>Hi, I'm Jared</Typography>

            <Box textAlign='center' sx={{marginBottom: 5}}>
                <Box
                    component="img"
                    src={"me.png"}
                    sx={{width: '200px', height: '200px', borderRadius: "25%"}}
                />
                <Typography sx={{color: 'gray'}}>
                    Image made with AI. If that sounds cool to you, shoot me a message
                </Typography>
            </Box>

            <Typography style={{fontSize: "large", padding: "3px",  marginBottom: "12px"}}>
                Thank you for taking the time to come to my site.
                I am a software professional specializing in cloud applications, data automation, and web development.

                I spend most of my time helping businesses build highly available and automated technology.
                I make software easier to build, use, and maintain.
            </Typography>
            <Typography style={{fontSize: "large", padding: "3px", marginBottom: "12px"}}>
                I also teach software to people of all skill levels. If you want to learn software, I am your guy.
            </Typography>
            <Typography style={{fontSize: "large", padding: "3px", marginBottom: "12px"}}>
                To contact me, click the mail icon below to send an email or you can use linkedin. Look forward to hearing from you!
            </Typography>
            <Divider sx={{borderColor: divColor, margin: "12px"}}>
                            <Typography textAlign='center' sx={{marginTop: "12px", fontSize: '2rem'}}>Me Online</Typography>
            </Divider>

            <Box textAlign='center' sx={{padding: '8px',
                border: "solid 1px white",
                margin: "3px",
                borderRadius: "20px"
            }}>
                <IconButton href="mailto:jared@jaredbwelch.com" sx={{color: 'var(--font-color)'}}>
                    <EmailIcon sx={{height: '50px', width: '50px'}}/>
                </IconButton>

                <IconButton href="https://www.linkedin.com/in/jaredbwelch" sx={{color: 'var(--font-color)'}}>
                    <LinkedInIcon sx={{height: '50px', width: '50px'}}/>
                </IconButton>
                <IconButton href="https://github.com/jaredwelch1" sx={{
                    color: 'var(--font-color)',
                }}>
                    <GitHubIcon sx={{height: '50px', width: '50px'}}/>
                </IconButton>
                {/*<a href="https://www.buymeacoffee.com/jaredbwelch" target="_blank">*/}
                {/*    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png" alt="Buy Me A Coffee"/>*/}
                {/*</a>*/}
                {/*<script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="jaredbwelch" data-color="#40DCA5" data-emoji="☕" data-font="Cookie" data-text="" data-outline-color="#000000" data-font-color="#ffffff" data-coffee-color="#FFDD00" ></script>*/}
            </Box>

            <Typography textAlign='center' sx={{fontSize: "2rem", margin: "6px"}}>Projects</Typography>


            <Container sx={{display: "flex", justifyContent: "center"}}>
                <ProjectBadge title={"Feedcombinator"}
                    description={"Organize your podcasts and online content with a free website that works well on mobile and desktop screens"}
                    targetUrl={"https://feedcombinator.com"}
                />
            </Container>
            <Container sx={{display: "flex", justifyContent: "center"}}>
                <ProjectBadge
                    title={"Cryptodashboard.net"}
                    description={"Your fast and simple crypto data dashboard for checking in on the state of cryptocurrency"}
                    targetUrl="https://cryptodashboard.net"
                />
            </Container>




            {/*<Typography textAlign='center' sx={{marginTop: '10px'}}>*/}
            {/*    If you have a cool idea you want to build and you think I can help, <a href="">send me a message.</a>*/}
            {/*</Typography>*/}

        </Container>
    )
}

export default AboutMe;
