import {Button, Container, Dialog, DialogTitle, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const SubscribeDialog = () => {
    const navigate = useNavigate()
    const [isOpen, setOpen] = useState();
    const [userEmail, setEmail] = useState();
    const [error, setError] = useState();

    const handleSubmit = (e) => {
        if (e){
            e.preventDefault()
        }

        fetch(`${process.env.REACT_APP_API_BASE_URL}/user/subscribe`, {
            method: "POST",
            body: JSON.stringify({
                "email": userEmail
            })
        }).then((resp) => {
            console.log(resp)
            if (!resp.ok){
                setError("Could not subscribe. Sorry about that.")
            } else {
                navigate("/thankyou")
            }
        })
    }

    return (
        <Container>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Button
                    sx={{
                        color: "var(--color-font-nav)",
                        backgroundColor: "var(--color-foreground)",
                        "&:hover": {backgroundColor: "#F3CFC6"}
                    }}
                    textAlign='center' onClick={()=>setOpen(true)}>
                    Subscribe
                </Button>
            </Container>

            <Dialog
                open={isOpen}
                onClose={() => setOpen(false)}
            >
                <DialogTitle
                    textAlign='center'
                    sx={{color: "black"}}
                >
                    Subscribe for Updates
                </DialogTitle>
                <Container sx={{
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    padding: "15px 0px",
                }}>
                    <Typography sx={{width: "350px"}}>
                        This is the best way to get updates from me in an automatic
                        fashion. Also this is a great way for me to know you support
                        the blog and the content.

                        If you choose to subscribe, I would appreciate it very much.
                        - Jared
                    </Typography>
                    <TextField
                        label="email"
                        onChange={({target}) => setEmail(target.value)}
                    />
                    <Button onClick={handleSubmit}>Submit</Button>
                    <Typography textAlign='center' color={'red'}>
                        {error && error}
                    </Typography>
                </Container>
            </Dialog>
        </Container>
    );
}

export default SubscribeDialog;
